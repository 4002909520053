import React, { useState } from 'react';

const SongLyricsPage = () => {
  const [selectedSong, setSelectedSong] = useState(null);

  const songs = [
    {
      title: 'Crescent Moon',
      lyrics: `
I'd sift
Through the weather in my pockets no complaints

A kind shift
In direction came my way

There's a lit match in the ocean
There's a testament to joy this crescent moon

There's an open ended day dream
There's a blonde hair colour changing crescent moon

We stumbled through the mud walkway together
We stumbled through the thought to be short cut

She's seen me step between the cracks
She's seen me run back to tap the door

There's a lit match in the ocean
There's a testament to joy this crescent moon

There's an open ended day dream
There's a blonde hair colour changing crescent moon
      `
    },
    {
      title: 'Knees Deep',
      lyrics: `
If the stars align
In the wrong way

If the dice lands on seven
If you're praying for a new un-spoilt day

If the dam breaks free
And the water might flood your faith

Just trust in me and the things we've seen
And our stone seal will beat the rain

If the stars align
In the wrong way

If the dice lands on seven
If you're praying for a new un-spoilt day
      `
    }
    /*{
        title: 'Tunnels',
        lyrics: `
I've seen this happen countless times
The endless passion in your eyes
Is really the only thing you should fear

But I'll go and join you anyway
In climbing bales of hay with hiking gear

And then we'll climb this hill in our bear feet

And our desire to reach the top
can never get us one step closer

But we'll feel like we've been
there almost 10 times over

And that's the thing about tunnels
You'll never reach the end
We've been here for so long
we've just settled in instead

And that's the thing about tunnels
You'll never reach the end
We've been here for so long
I know the names of everyone that's been and left

Like a paper clip that's envious
of the stack it's got to leave behind

I knew I couldn't stay
I knew it wasn't wise

And I'm hiding all my might inside the kitchen dances
It's just me and them and late nights that have sweet and short romances

Bus rides with old friends surprise me like a new thorn in my skin
Didn't have to sleep with my eyes open but I know that I did

And that's the thing about tunnels
You'll never reach the end
We've been here for so long
we've just settled in instead

And that's the thing about tunnels
You'll never reach the end
We've been here for so long
I know the names of everyone that's been and left
        `
      }*/
  ];

  const handleSongChange = (event) => {
    const selectedSongTitle = event.target.value;
    const song = songs.find(song => song.title === selectedSongTitle);
    setSelectedSong(song);
  };

  return (
    <div className="page song-lyrics-page">
      <h1>Song Lyrics</h1>
      <select class="select_button" id="songLyricsList" onChange={handleSongChange}>
        <option>Select Song</option>
        {songs.map((song, index) => (
          <option key={index} value={song.title}>{song.title}</option>
        ))}
      </select>
      {selectedSong && (
        <div className="lyrics-section">
          <h2>{selectedSong.title}</h2>
          <pre>{selectedSong.lyrics}</pre>
        </div>
      )}
    </div>
  );
}

export default SongLyricsPage;
