import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import SongLyricsPage from './SongLyricsPage'; // Import the new component

const legends_gig_poster = require('./gig_images/legends_bar_poster.png');
const seven_stars_gig_poster = require('./gig_images/seven_stars_poster.png');
const sumac_centre_poster = require('./gig_images/sumac_centre_poster.png');
const billy_bootleggers_poster = require('./gig_images/billy_bootleggers_poster.png');

function HomePage() {
  const [showNewMusic, setShowNewMusic] = useState(true); //true shows new music false doesn't.

  return (
    <div className="page home-page">
      <h1>Welcome to the Lefthouse Website</h1>
      <p>Have a look around to find information about gig dates, music releases, and more!</p>
      <h2>Bio</h2>
      <p id="aboutusDesc">
        Lefthouse are a Nottinghamshire (UK) based rock band that began in mid-2022, creating and performing music somewhere on the spectrum between Post-Punk and Noise Rock.
      </p>
      <p id="aboutusDesc2">
        The band was founded and has always consisted of members Aaron Ashworth (Vocals, Guitar), Freddie Jones (Guitar), Zach Bladen (Bass) and Ben Wilson (Drums, Miscellaneous skill haver). Their sound is characterized by punchy, droning bass, loud and rhythmic guitars; and are heavily incrusted by symbolic, mellow vocals.
      </p>
      {showNewMusic && (
        <a id="latest_release_link" href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
          <h2>Latest Release:</h2>
          <p>Click here to listen to our new 2-track single 'Crescent Moon'.</p>
          <img id="cm_cover_img" src={require('./other_images/Cover_Crescent_Moon.png')} alt="Lefthouse Crescent Moon Cover Image" />
          <h2 className="blue_text">^ Click to listen ^</h2>
        </a>
      )}
    </div>
  );
}

function GigsPage() {
  const [gigs, setGigs] = useState([]);

  useEffect(() => {
    setGigs(gigsData);
  }, []);

  // Function to format text
  const formatText = (text) => {
    // Replace **text** with <strong>text</strong> and *text* with <em>text</em>
    return text.split(/(\*\*.*?\*\*|\*.*?\*)/g).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove the double asterisks
      } else if (part.startsWith('*') && part.endsWith('*')) {
        return <em key={index}>{part.slice(1, -1)}</em>; // Remove the single asterisks
      }
      return part; // Return the part as is if no formatting
    });
  };

  return (
    <div className="gigs-page" id="gigs">
      <h1 id="gigs_page_title">Lefthouse Gigs</h1>
      <section className="gig-section">
        <h1>Gigography</h1>

        {/* Display gigs in a table */}
        <table className="gig-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Venue</th> 
              <th>Host</th>
              <th>Lineup</th>
            </tr>
          </thead>
          <tbody>
            {gigs.map((gig, index) => (
              <tr key={index}>
                <td>{gig.date}</td>
                <td>{formatText(gig.venue)}</td>
                <td>{formatText(gig.host)}</td>
                <td>{formatText(gig.line_up)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

var notFromLondon = "I'm Not From London"
const gigsData = [
  {
    date: '15/11/2024',
    venue: 'The Victoria Inn, *Derbyshire*',
    host: 'MUSIKBOX',
    line_up: '**Skeeve**, Lefthouse, Hot-Fuzz',
  },
  {
    date: '10/09/2024',
    venue: 'Billy Bootleggers, *Nottingham*',
    host: notFromLondon,
    line_up: '**Unit Six**, Hex Undone, Lefthouse',
  },
  {
    date: '20/07/2024',
    venue: 'The Seven Stars, *Derbyshire*',
    host: 'Self-Hosted',
    line_up: '**Lefthouse**, Vi',

  },
  {
    date: '17/02/2024',
    venue: 'The Seven Stars, *Derbyshire*',
    host: 'Self-Hosted',
    line_up: '**Lefthouse**, Vi',
  },
  {
    date: '01/12/2023',
    venue: 'Legends Sports Bar, *Mansfield*',
    host: 'Thundergroove Promotions',
    line_up: '**Static Attic**, Solomon Sage, Five Second Fuse, Lefthouse'
  },
];

function SocialMediaPage() {
  return (
    <div className="page socialmedia-page">
      <h1>Social Media</h1>
      <p className="inline100">Be sure to follow us on Social Media to stay updated in terms of what we're up to!</p>
      <div className="videoLinks">
        <h4>Follow Us On Spotify</h4>
        <div className="videoLinks">
          <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
            <img className="social-image" id="spotify-image" src={require('./logos/spotify-logo.png')} alt="Facebook image" />
          </a>
        </div>
        <h4>Follow Us On Instagram</h4>
        <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="instagram_image" src={require('./logos/insta-logo.png')} alt="Lefthouse Instagram" />
        </a>
      </div>
      <h4>Follow Us On YouTube</h4>
      <div className="videoLinks">
        <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
          <img className="social-image" src={require('./logos/youtube_channel_thumbnail.png')} alt="Lefthouse YouTube channel thumbnail image" />
        </a>
      </div>
      <h4>Follow Us On Facebook</h4>
      <div className="videoLinks">
        <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="facebook_image" src={require('./logos/facebook-logo.png')} alt="Facebook image" />
        </a>
      </div>
    </div>
  );
}

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
      <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
        <div className="above_navigation">
          <img id="lefthouse_banner_logo" src={require('./lefthouse_banner_logo.png')} alt="Lefthouse Logo" />
          <div className="media_links">
            <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
              <img className="social-image" id="instagram_nav_image" src={require('./logos/insta-logo.png')} alt="Lefthouse Instagram" />
            </a>
            <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
              <img className="social-image" id="spotify_nav_image" src={require('./logos/spotify-logo.png')} alt="Facebook image" />
            </a>
            <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
              <img className="social-image" id="youtube_nav_image" src={require('./logos/youtube_channel_thumbnail.png')} alt="Lefthouse YouTube channel thumbnail image" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
              <img className="social-image" id="facebook_nav_image" src={require('./logos/facebook-logo.png')} alt="Facebook image" />
            </a>
          </div>
        </div>
        <nav className="navigation">
          <NavLink exact to="/" activeClassName="active">/home/</NavLink>
          <NavLink to="/gigs" activeClassName="active">/gigography/</NavLink>
          <NavLink to="/socialmedia" activeClassName="active">/social media/</NavLink>
          <NavLink to="/lyrics" activeClassName="active">/song lyrics/</NavLink>
        </nav>
        <div className="container">
          <ScrollToTopOnMount />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/gigs" element={<GigsPage />} />
            <Route path="/socialmedia" element={<SocialMediaPage />} />
            <Route path="/lyrics" element={<SongLyricsPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
